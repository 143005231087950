.custom-datepicker {
  height: 20px;
  width: 94%;
  padding: 5px;
  font-size: 15px; /* Defina o tamanho da fonte desejado para os números dos dias */
}

.error {
  border-color: red;
}

.react-datepicker-wrapper {
  width: 100%;
}

.MuiAutocomplete-root {
  margin: 0px !important;
  margin-right: 15px !important;
}