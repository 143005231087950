.custom-datepicker {
  height: 20px;
  width: 94%;
  padding: 5px;
  font-size: 15px; /* Defina o tamanho da fonte desejado para os números dos dias */
}

.custom-datepicker-registrationDeadline {
  height: 20px;
  width: 106%;
  padding: 5px;
  font-size: 15px; /* Defina o tamanho da fonte desejado para os números dos dias */
}

.error {
  border-color: red;
}