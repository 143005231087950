.error {
  border-color: red;
}

.date-picker-schedule {
  margin-bottom: 10px;
  margin-left: 10px;
  height: 28px !important;
  width: 90% !important;
  /* padding: 5px; */
  font-size: 15px; /* Defina o tamanho da fonte desejado para os números dos dias */
  border-radius: 5px;
  border-width: 1px !important;
}
