/* global.css */

body {
  font-family: "Open Sans", sans-serif; /* Aplica a Open Sans a todo o texto do corpo */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif; /* Aplica a Open Sans a todos os títulos */
}

p {
  font-family: "Open Sans", sans-serif; /* Aplica a Open Sans a todos os títulos */
}

/* Outros elementos de texto podem ser estilizados da mesma forma */
