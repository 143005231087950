.custom-datepicker {
  height: 30px !important;
  width: 94% !important;
  padding: 5px;
  font-size: 15px; /* Defina o tamanho da fonte desejado para os números dos dias */
  border-radius: 5px;
  border-width: 1px;
  border-top-color: #00000050;
  border-left-color: #00000050;
  margin-left: 2px;
}

.error {
  border-color: red;
}

.react-datepicker-wrapper {
  width: 100%;
}
